import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { useState } from 'react';
import { Link, Table } from 'hds-react';
import ExternalLink from '../../../components/ExternalLink';
import Playground from '../../../components/Playground';
import TabsLayout from './tabs.mdx';
export const _frontmatter = {
  "slug": "/components/table/code",
  "title": "Table - Code"
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const IconCheckCircleFill = makeShortcode("IconCheckCircleFill");
const layoutProps = {
  _frontmatter
};
const MDXLayout = ({
  children,
  pageContext
}) => <TabsLayout pageContext={pageContext}>{children}</TabsLayout>;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "code",
      "style": {
        "position": "relative"
      }
    }}>{`Code`}<a parentName="h2" {...{
        "href": "#code",
        "aria-label": "code permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <h3 {...{
      "id": "code-examples",
      "style": {
        "position": "relative"
      }
    }}>{`Code examples`}<a parentName="h3" {...{
        "href": "#code-examples",
        "aria-label": "code examples permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <h4 {...{
      "id": "default",
      "style": {
        "position": "relative"
      }
    }}>{`Default`}<a parentName="h4" {...{
        "href": "#default",
        "aria-label": "default permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <Playground mdxType="Playground">
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`{
  () => {
    const cols = [
      { key: 'id', headerName: 'Not rendered' },
      { key: 'firstName', headerName: 'First name' },
      { key: 'surname', headerName: 'Surname' },
      {
        key: 'age',
        headerName: 'Age',
        transform: ({ age }) => {
          return <div style={{ textAlign: 'right' }}>{age}</div>;
        },
      },
      { key: 'profession', headerName: 'Profession' },
    ];
    const rows = [
      { id: 1000, firstName: 'Lauri', surname: 'Kekkonen', age: 39, profession: 'Engineer' },
      { id: 1001, firstName: 'Maria', surname: 'Sarasoja', age: 62, profession: 'Designer' },
      { id: 1002, firstName: 'Anneli', surname: 'Routa', age: 50, profession: 'Meteorologist' },
      { id: 1003, firstName: 'Osku', surname: 'Rausku', age: 18, profession: 'Mail Carrier' },
    ];
    const captionDark = (
      <span>
        <b>Table 1</b>: Service users (dark variant)
      </span>
    );
    const captionLight = (
      <span>
        <b>Table 2</b>: Service users (light variant)
      </span>
    );
    return (
      <div style={{ maxWidth: '640px' }}>
        <Table cols={cols} rows={rows} caption={captionDark} indexKey="id" renderIndexCol={false} />
        <div style={{ marginTop: 'var(--spacing-l)' }}>
          <Table cols={cols} rows={rows} caption={captionLight} variant="light" indexKey="id" renderIndexCol={false} />
        </div>
      </div>
    );
  };
}
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-html"
        }}>{`<div>
  <div class="hds-table-container" style="max-width:640px" tabindex="0">
    <table class="hds-table hds-table--dark" aria-label="Service users (dark variant)">
      <caption class="hds-table__caption">
        <b>Table 1</b
        >: Service users (dark variant)
      </caption>
      <thead>
        <tr class="hds-table__header-row">
          <th scope="col">First name</th>
          <th scope="col">Surname</th>
          <th scope="col">Age</th>
          <th scope="col">Profession</th>
        </tr>
      </thead>
      <tbody class="hds-table__content">
        <tr>
          <td>Lauri</td>
          <td>Kekkonen</td>
          <td class="hds-table--text-align-right">39</td>
          <td>Engineer</td>
        </tr>
        <tr>
          <td>Maria</td>
          <td>Sarasoja</td>
          <td class="hds-table--text-align-right">62</td>
          <td>Designer</td>
        </tr>
        <tr>
          <td>Anneli</td>
          <td>Routa</td>
          <td class="hds-table--text-align-right">50</td>
          <td>Meteorologist</td>
        </tr>
        <tr>
          <td>Osku</td>
          <td>Rausku</td>
          <td class="hds-table--text-align-right">18</td>
          <td>Mail carrier</td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="hds-table-container" style="max-width:640px" tabindex="0">
    <table class="hds-table hds-table--light" aria-label="Service users (light variant)">
      <caption class="hds-table__caption">
        <b>Table 2</b
        >: Service users (light variant)
      </caption>
      <thead>
        <tr class="hds-table__header-row">
          <th scope="col">First name</th>
          <th scope="col">Surname</th>
          <th scope="col">Age</th>
          <th scope="col">Profession</th>
        </tr>
      </thead>
      <tbody class="hds-table__content">
        <tr>
          <td>Lauri</td>
          <td>Kekkonen</td>
          <td class="hds-table--text-align-right">39</td>
          <td>Engineer</td>
        </tr>
        <tr>
          <td>Maria</td>
          <td>Sarasoja</td>
          <td class="hds-table--text-align-right">62</td>
          <td>Designer</td>
        </tr>
        <tr>
          <td>Anneli</td>
          <td>Routa</td>
          <td class="hds-table--text-align-right">50</td>
          <td>Meteorologist</td>
        </tr>
        <tr>
          <td>Osku</td>
          <td>Rausku</td>
          <td class="hds-table--text-align-right">18</td>
          <td>Mail carrier</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
`}</code></pre>
    </Playground>
    <h4 {...{
      "id": "vertical-headers",
      "style": {
        "position": "relative"
      }
    }}>{`Vertical headers`}<a parentName="h4" {...{
        "href": "#vertical-headers",
        "aria-label": "vertical headers permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <Playground mdxType="Playground">
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`{
  () => {
    const cols = [
      { key: 'id', headerName: 'Not rendered' },
      { key: '8-12', headerName: '8-12' },
      { key: '12-14', headerName: '12-14' },
      { key: '14-16', headerName: '14-16' },
      { key: '16-18', headerName: '16-18' },
    ];
    const unitRows = [
      { id: 'monday', '8-12': 324, '12-14': 562, '14-16': 280, '16-18': 94 },
      { id: 'tuesday', '8-12': 341, '12-14': 688, '14-16': 425, '16-18': 113 },
      { id: 'wednesday', '8-12': 294, '12-14': 492, '14-16': 280, '16-18': 67 },
      { id: 'thursday', '8-12': 312, '12-14': 501, '14-16': 455, '16-18': 112 },
      { id: 'friday', '8-12': 150, '12-14': 142, '14-16': 362, '16-18': 455 },
    ];
    const verticalHeaders = [
      { key: 'monday', headerName: 'Monday' },
      { key: 'tuesday', headerName: 'Tuesday' },
      { key: 'wednesday', headerName: 'Wednesday' },
      { key: 'thursday', headerName: 'Thursday' },
      { key: 'friday', headerName: 'Friday' },
    ];
    const caption = (
      <span>
        <b>Table 3</b>: Units sold by weekday
      </span>
    );
    return (
      <div style={{ maxWidth: '486px' }}>
        <Table
          verticalHeaders={verticalHeaders}
          cols={cols}
          rows={unitRows}
          caption={caption}
          textAlignContentRight
          indexKey="id"
          renderIndexCol={false}
        />
      </div>
    );
  };
}
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-html"
        }}>{`<div class="hds-table-container" style="max-width:496px;" tabindex="0">
  <table class="hds-table hds-table--dark hds-table--with-vertical-header" aria-label="Units sold by weekday">
    <caption class="hds-table__caption">
      <b>Table 3</b
      >: Units sold by weekday
    </caption>
    <colgroup>
      <col span="1" class="hds-table__vertical-header-column" />
    </colgroup>
    <thead>
      <tr class="hds-table__header-row">
        <td role="presentation"></td>
        <th scope="col">8-12</th>
        <th scope="col">12-14</th>
        <th scope="col">14-16</th>
        <th scope="col">16-18</th>
      </tr>
    </thead>
    <tbody class="hds-table__content hds-table__content--text-align-td-right">
      <tr>
        <th scope="row">Monday</th>
        <td>324</td>
        <td>562</td>
        <td>280</td>
        <td>94</td>
      </tr>
      <tr>
        <th scope="row">Tuesday</th>
        <td>341</td>
        <td>688</td>
        <td>425</td>
        <td>113</td>
      </tr>
      <tr>
        <th scope="row">Wednesday</th>
        <td>294</td>
        <td>492</td>
        <td>280</td>
        <td>67</td>
      </tr>
      <tr>
        <th scope="row">Thursday</th>
        <td>312</td>
        <td>501</td>
        <td>455</td>
        <td>112</td>
      </tr>
      <tr>
        <th scope="row">Friday</th>
        <td>150</td>
        <td>142</td>
        <td>362</td>
        <td>455</td>
      </tr>
    </tbody>
  </table>
</div>
`}</code></pre>
    </Playground>
    <h4 {...{
      "id": "zebra-rows",
      "style": {
        "position": "relative"
      }
    }}>{`Zebra rows`}<a parentName="h4" {...{
        "href": "#zebra-rows",
        "aria-label": "zebra rows permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <Playground mdxType="Playground">
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`{
  () => {
    const cols = [
      { key: 'id', headerName: 'Not rendered' },
      { key: 'firstName', headerName: 'First name' },
      { key: 'surname', headerName: 'Surname' },
      {
        key: 'age',
        headerName: 'Age',
        transform: ({ age }) => {
          return <div style={{ textAlign: 'right' }}>{age}</div>;
        },
      },
      { key: 'city', headerName: 'City' },
      { key: 'profession', headerName: 'Profession' },
      {
        key: 'experience',
        headerName: 'Experience (years)',
        transform: ({ experience }) => {
          return <div style={{ textAlign: 'right' }}>{experience}</div>;
        },
      },
    ];
    const rows = [
      {
        id: 1000,
        firstName: 'Lauri',
        surname: 'Kekkonen',
        age: 39,
        city: 'Helsinki',
        profession: 'Engineer',
        experience: 10,
      },
      {
        id: 1001,
        firstName: 'Maria',
        surname: 'Sarasoja',
        age: 62,
        city: 'Tampere',
        profession: 'Designer',
        experience: 39,
      },
      {
        id: 1002,
        firstName: 'Anneli',
        surname: 'Routa',
        age: 50,
        city: 'Turku',
        profession: 'Meteorologist',
        experience: 25,
      },
      {
        id: 1003,
        firstName: 'Osku',
        surname: 'Rausku',
        age: 18,
        city: 'Oulu',
        profession: 'Mail Carrier',
        experience: 1,
      },
    ];
    const caption = (
      <span>
        <b>Table 4</b>: Service users (zebra variant)
      </span>
    );
    return (
      <div style={{ maxWidth: '800px' }}>
        <Table rows={rows} cols={cols} caption={caption} zebra indexKey="id" renderIndexCol={false} />
      </div>
    );
  };
}
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-html"
        }}>{`<div class="hds-table-container" style="max-width:800px;" tabindex="0">
  <table class="hds-table hds-table--dark hds-table--zebra" aria-label="Service users (zebra variant)">
    <caption class="hds-table__caption">
      <b>Table 4</b
      >: Service users (zebra variant)
    </caption>
    <thead>
      <tr class="hds-table__header-row">
        <th scope="col">First name</th>
        <th scope="col">Surname</th>
        <th scope="col">Age</th>
        <th scope="col">City</th>
        <th scope="col">Profession</th>
        <th scope="col">Experience (years)</th>
      </tr>
    </thead>
    <tbody class="hds-table__content">
      <tr>
        <td>Lauri</td>
        <td>Kekkonen</td>
        <td class="hds-table--text-align-right">39</td>
        <td>Helsinki</td>
        <td>Engineer</td>
        <td class="hds-table--text-align-right">10</td>
      </tr>
      <tr>
        <td>Maria</td>
        <td>Sarasoja</td>
        <td class="hds-table--text-align-right">62</td>
        <td>Tampere</td>
        <td>Designer</td>
        <td class="hds-table--text-align-right">39</td>
      </tr>
      <tr>
        <td>Anneli</td>
        <td>Routa</td>
        <td class="hds-table--text-align-right">50</td>
        <td>Turku</td>
        <td>Meteorologist</td>
        <td class="hds-table--text-align-right">25</td>
      </tr>
      <tr>
        <td>Osku</td>
        <td>Rausku</td>
        <td class="hds-table--text-align-right">18</td>
        <td>Oulu</td>
        <td>Mail Carrier</td>
        <td class="hds-table--text-align-right">1</td>
      </tr>
    </tbody>
  </table>
</div>
`}</code></pre>
    </Playground>
    <h4 {...{
      "id": "vertical-lines",
      "style": {
        "position": "relative"
      }
    }}>{`Vertical lines`}<a parentName="h4" {...{
        "href": "#vertical-lines",
        "aria-label": "vertical lines permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <Playground mdxType="Playground">
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`{
  () => {
    const cols = [
      { key: 'day', headerName: 'Day' },
      {
        key: 'product1',
        headerName: 'Product 1',
        transform: ({ product1 }) => {
          return <div style={{ textAlign: 'right' }}>{product1}</div>;
        },
      },
      {
        key: 'product2',
        headerName: 'Product 2',
        transform: ({ product2 }) => {
          return <div style={{ textAlign: 'right' }}>{product2}</div>;
        },
      },
      {
        key: 'product3',
        headerName: 'Product 3',
        transform: ({ product3 }) => {
          return <div style={{ textAlign: 'right' }}>{product3}</div>;
        },
      },
    ];
    const rows = [
      { day: 'Monday', product1: 12, product2: 24, product3: 5 },
      { day: 'Tuesday', product1: 10, product2: 32, product3: 6 },
      { day: 'Wednesday', product1: 14, product2: 35, product3: 6 },
      { day: 'Thursday', product1: 11, product2: 18, product3: 2 },
      { day: 'Friday', product1: 16, product2: 45, product3: 12 },
    ];
    const caption = (
      <span>
        <b>Table 5</b>: Units sold by weekday (vertical lines variant)
      </span>
    );
    return (
      <div style={{ maxWidth: '496px' }}>
        <Table verticalLines cols={cols} rows={rows} caption={caption} indexKey="day" renderIndexCol={true} />
      </div>
    );
  };
}
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-html"
        }}>{`<div class="hds-table-container" style={{maxWidth: '496px'}} tabindex="0">
    <table class="hds-table hds-table--dark hds-table--with-vertical-lines" aria-label="Units sold by weekday">
      <caption class="hds-table__caption"><b>Table 1</b>: Table description</caption>
      <thead>
        <tr class="hds-table__header-row">
          <th scope="col">Day</th>
          <th scope="col">Product 1</th>
          <th scope="col">Product 2</th>
          <th scope="col">Product 3</th>
        </tr>
      </thead>
      <tbody class="hds-table__content">
        <tr>
          <td>Monday</td>
          <td class="hds-table--text-align-right">12</td>
          <td class="hds-table--text-align-right">24</td>
          <td class="hds-table--text-align-right">5</td>
        </tr>
        <tr>
          <td>Tuesday</td>
          <td class="hds-table--text-align-right">10</td>
          <td class="hds-table--text-align-right">32</td>
          <td class="hds-table--text-align-right">6</td>
        </tr>
        <tr>
          <td>Wednesday</td>
          <td class="hds-table--text-align-right">14</td>
          <td class="hds-table--text-align-right">35</td>
          <td class="hds-table--text-align-right">6</td>
        </tr>
        <tr>
          <td>Thursday</td>
          <td class="hds-table--text-align-right">11</td>
          <td class="hds-table--text-align-right">18</td>
          <td class="hds-table--text-align-right">2</td>
        </tr>
        <tr>
          <td>Friday</td>
          <td class="hds-table--text-align-right">16</td>
          <td class="hds-table--text-align-right">45</td>
          <td class="hds-table--text-align-right">12</td>
        </tr>
      </tbody>
    </table>
  </div>
`}</code></pre>
    </Playground>
    <h4 {...{
      "id": "dense-tables",
      "style": {
        "position": "relative"
      }
    }}>{`Dense tables`}<a parentName="h4" {...{
        "href": "#dense-tables",
        "aria-label": "dense tables permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <Playground mdxType="Playground">
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`{
  () => {
    const cols = [
      { key: 'id', headerName: 'Not rendered' },
      { key: 'firstName', headerName: 'First name' },
      { key: 'surname', headerName: 'Surname' },
      {
        key: 'age',
        headerName: 'Age',
        transform: ({ age }) => {
          return <div style={{ textAlign: 'right' }}>{age}</div>;
        },
      },
      { key: 'profession', headerName: 'Profession' },
    ];
    const rows = [
      { id: 1000, firstName: 'Lauri', surname: 'Kekkonen', age: 39, profession: 'Engineer' },
      { id: 1001, firstName: 'Maria', surname: 'Sarasoja', age: 62, profession: 'Designer' },
      { id: 1002, firstName: 'Anneli', surname: 'Routa', age: 50, profession: 'Meteorologist' },
      { id: 1003, firstName: 'Osku', surname: 'Rausku', age: 18, profession: 'Mail Carrier' },
    ];
    const caption = (
      <span>
        <b>Table 6</b>: Service users (dense variant)
      </span>
    );
    return (
      <div style={{ maxWidth: '500px' }}>
        <Table cols={cols} rows={rows} dense caption={caption} indexKey="id" renderIndexCol={false} />
      </div>
    );
  };
}
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-html"
        }}>{`<div class="hds-table-container" style="max-width: 640px;" tabindex="0">
  <table class="hds-table hds-table--dark hds-table--dense" aria-label="People">
    <caption class="hds-table__caption">
      <b>Table 6</b
      >: Service users (dense variant)
    </caption>
    <thead>
      <tr class="hds-table__header-row">
        <th scope="col">First name</th>
        <th scope="col">Surname</th>
        <th scope="col">Age</th>
        <th scope="col">Profession</th>
      </tr>
    </thead>
    <tbody class="hds-table__content">
      <tr>
        <td>Lauri</td>
        <td>Kekkonen</td>
        <td class="hds-table--text-align-right">39</td>
        <td>Engineer</td>
      </tr>
      <tr>
        <td>Maria</td>
        <td>Sarasoja</td>
        <td class="hds-table--text-align-right">62</td>
        <td>Designer</td>
      </tr>
      <tr>
        <td>Anneli</td>
        <td>Routa</td>
        <td class="hds-table--text-align-right">50</td>
        <td>Meteorologist</td>
      </tr>
      <tr>
        <td>Osku</td>
        <td>Rausku</td>
        <td class="hds-table--text-align-right">18</td>
        <td>Mail carrier</td>
      </tr>
    </tbody>
  </table>
</div>
`}</code></pre>
    </Playground>
    <h4 {...{
      "id": "sorting",
      "style": {
        "position": "relative"
      }
    }}>{`Sorting`}<a parentName="h4" {...{
        "href": "#sorting",
        "aria-label": "sorting permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <Playground mdxType="Playground">
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`{
  () => {
    const cols = [
      { key: 'id', headerName: 'Not rendered' },
      { key: 'firstName', headerName: 'First name', isSortable: true },
      { key: 'surname', headerName: 'Surname', isSortable: true },
      {
        key: 'age',
        headerName: 'Age',
        sortIconType: 'other',
        transform: ({ age }) => {
          return <div style={{ textAlign: 'right' }}>{age}</div>;
        },
        isSortable: true,
      },
      { key: 'profession', headerName: 'Profession', isSortable: true },
    ];
    const rows = [
      { id: 1000, firstName: 'Lauri', surname: 'Kekkonen', age: 39, profession: 'Engineer' },
      { id: 1001, firstName: 'Maria', surname: 'Sarasoja', age: 62, profession: 'Designer' },
      { id: 1002, firstName: 'Anneli', surname: 'Routa', age: 50, profession: 'Meteorologist' },
      { id: 1003, firstName: 'Osku', surname: 'Rausku', age: 18, profession: 'Mail Carrier' },
      { id: 1004, firstName: 'Linda', surname: 'Koululainen', age: 8, profession: 'School student' },
    ];
    const caption = (
      <span>
        <b>Table 7</b>: Service users (sorting, dark variant)
      </span>
    );
    return (
      <div style={{ maxWidth: '640px' }}>
        <Table
          ariaLabelSortButtonUnset="Not sorted"
          ariaLabelSortButtonAscending="Sorted in ascending order"
          ariaLabelSortButtonDescending="Sorted in descending order"
          indexKey="id"
          renderIndexCol={false}
          cols={cols}
          rows={rows}
          caption={caption}
        />
      </div>
    );
  };
}
`}</code></pre>
    </Playground>
    <h4 {...{
      "id": "selecting-rows",
      "style": {
        "position": "relative"
      }
    }}>{`Selecting rows`}<a parentName="h4" {...{
        "href": "#selecting-rows",
        "aria-label": "selecting rows permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <Playground mdxType="Playground">
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`{
  () => {
    const cols = [
      { key: 'id', headerName: 'Not rendered' },
      { key: 'firstName', headerName: 'First name' },
      { key: 'surname', headerName: 'Surname' },
      {
        key: 'age',
        headerName: 'Age',
        transform: ({ age }) => {
          return <div style={{ textAlign: 'right' }}>{age}</div>;
        },
      },
      { key: 'profession', headerName: 'Profession' },
    ];
    const rows = [
      { id: 1000, firstName: 'Lauri', surname: 'Kekkonen', age: 39, profession: 'Engineer' },
      { id: 1001, firstName: 'Maria', surname: 'Sarasoja', age: 62, profession: 'Designer' },
      { id: 1002, firstName: 'Anneli', surname: 'Routa', age: 50, profession: 'Meteorologist' },
      { id: 1003, firstName: 'Osku', surname: 'Rausku', age: 18, profession: 'Mail Carrier' },
    ];
    const [selectedRows, setSelectedRows] = useState([]);
    return (
      <div style={{ maxWidth: '640px' }}>
        <Table
          checkboxSelection
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          heading="Employees"
          id="checkbox-selection"
          indexKey="id"
          renderIndexCol={false}
          cols={cols}
          rows={rows}
          selectAllRowsText="Select all rows"
          clearSelectionsText="Clear selections"
          ariaLabelCheckboxSelection="Row selection"
        />
      </div>
    );
  };
}
`}</code></pre>
    </Playground>
    <h3 {...{
      "id": "packages",
      "style": {
        "position": "relative"
      }
    }}>{`Packages`}<a parentName="h3" {...{
        "href": "#packages",
        "aria-label": "packages permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Package`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Included`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Storybook link`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Source link`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`HDS React`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><div style={{
              display: 'flex',
              gap: 'var(--spacing-3-xs)'
            }}><IconCheckCircleFill mdxType="IconCheckCircleFill" />{` Yes `}</div></td>
          <td parentName="tr" {...{
            "align": null
          }}><Link openInNewTab openInNewTabAriaLabel="Opens in a new tab" href="/storybook/react/?path=/story/components-table--default" mdxType="Link">{`View in Storybook`}</Link></td>
          <td parentName="tr" {...{
            "align": null
          }}><ExternalLink openInNewTab openInNewTabAriaLabel="Opens in a new tab" href="https://github.com/City-of-Helsinki/helsinki-design-system/tree/master/packages/react/src/components/table" mdxType="ExternalLink">{`View source`}</ExternalLink></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`HDS Core`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><div style={{
              display: 'flex',
              gap: 'var(--spacing-3-xs)'
            }}><IconCheckCircleFill mdxType="IconCheckCircleFill" />{` Yes `}</div></td>
          <td parentName="tr" {...{
            "align": null
          }}><Link openInNewTab openInNewTabAriaLabel="Opens in a new tab" href="/storybook/core/?path=/story/components-table--dark" mdxType="Link">{`View in Storybook`}</Link></td>
          <td parentName="tr" {...{
            "align": null
          }}><ExternalLink openInNewTab openInNewTabAriaLabel="Opens in a new tab" href="https://github.com/City-of-Helsinki/helsinki-design-system/tree/master/packages/core/src/components/table" mdxType="ExternalLink">{`View source`}</ExternalLink></td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "properties",
      "style": {
        "position": "relative"
      }
    }}>{`Properties`}<a parentName="h3" {...{
        "href": "#properties",
        "aria-label": "properties permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`Note! You can find the full list of properties in the `}<Link openInNewTab openInNewTabAriaLabel="Opens in a new tab" href="/storybook/react/?path=/docs/components-table--default" mdxType="Link">{`React Storybook.`}</Link></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Values`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default value`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ariaLabelCheckboxSelection`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Aria-label for checkbox selection.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`"Rivin valinta"`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ariaLabelSortButtonAscending`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Aria-label for sort button in ascending state.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`"Järjestetty nousevaan järjestykseen"`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ariaLabelSortButtonDescending`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Aria-label for sort button in descending state.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`"Järjestetty laskevaan järjestykseen"`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ariaLabelSortButtonUnset`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Aria-label for sort button in the unordered state.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`caption`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The caption of the table.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ReactNode`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`checkboxSelection`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If set to true, the table displays the checkbox selection column to select rows.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`clearSelectionsText`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Text for clear selected rows button.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`"Tyhjennä valinnat"`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`cols`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Columns of the table header row. Defines header name, optional sort icon type and optional cell row transform function.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`Header[]`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`customActionButtons`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Custom action buttons to place on top of the table.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ReactNode[]`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`dense`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If set to true, the dense variant of the table is used.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`heading`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The table heading.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`headingAriaLevel`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The table heading aria-level.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`number`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`headingId`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Table heading id. Used to name table to assistive technologies. Only applicable when heading prop is used.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`"hds-table-heading-id"`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`indexKey`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Column key used as a unique identifier for a row`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`initialSortingColumnKey`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Key indicating a column that you wish to be initially sorted. Use undefined to have no column initially sorted.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`initialSortingOrder`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Sorting order applied for initial sorting.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`"asc"`}</inlineCode>{` `}<inlineCode parentName="td">{`"desc"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`onSort`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Function for providing side effects before or after the sort. If you want to use the component's own sorting function, call the handleSort callback function.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`function`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`renderIndexCol`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If set to true, the index column is rendered in the table.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`true`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`rows`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Table rows. An array of objects where keys map with the keys of col.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`object[]`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`selectAllRowsText`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Text for the select all rows button.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`"Valitse kaikki rivit"`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`selectedRows`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Selected table rows.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`SelectedRow[]`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`setSelectedRows`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Callback that updates selected rows.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`function`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`textAlignContentRight`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`When set to true, table data cell text content is aligned right. When set to false (default), the text is aligned to left.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`variant`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Table variant. Use dark for dark brand background colors, and light for light brand background colors.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`"light"`}</inlineCode>{` `}<inlineCode parentName="td">{`"dark"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`"dark"`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`verticalHeaders`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Vertical headers of the table.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`Header[]`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`verticalLines`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If set to true, the table displays vertical lines on columns.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`zebra`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If set to true, the table uses alternating row colors zebra style.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`[Table 1:Table properties]`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      